var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.respData && _vm.$ability.can('read', this.$route.meta.resource))?_c('div',[_c('b-card',{attrs:{"title":_vm.$t('System')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',{attrs:{"head-variant":"primary"}},[_c('b-tr',[_c('b-td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(_vm.$t('System list')))]),_c('b-td',[_vm._v(_vm._s(_vm.$t('Setting')))]),_c('b-td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(_vm.$t('System list')))]),_c('b-td',[_vm._v(_vm._s(_vm.$t('Setting')))])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Wallet'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null ? _vm.respData['system']['wallet'] : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.wallet', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["wallet"] == true ? "เปิดระบบกระเป๋าเงิน" : "ปิดระบบกระเป๋าเงิน" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Point'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['point']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.point', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["point"] == true ? "เปิดระบบคะแนน" : "ปิดระบบคะแนน" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('E-Commerce'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null ? _vm.respData['system']['ecommerce'] : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.ecommerce', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["ecommerce"] == true ? "เปิดระบบซื้อขายออนไลน์" : "ปิดระบบซื้อขายออนไลน์" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Chat'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['chat']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.chat', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["chat"] == true ? "เปิดระบบแชท" : "ปิดระบบแชท" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Cart'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null ? _vm.respData['system']['cart'] : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.cart', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["cart"] == true ? "เปิดระบบตะกร้า" : "ปิดระบบตะกร้า" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Feed'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['feed']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.feed', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["feed"] == true ? "เปิดระบบฟีด" : "ปิดระบบฟีด" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Stock'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null ? _vm.respData['system']['stock'] : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.stock', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["stock"] == true ? "เปิดระบบคลังสินค้า" : "ปิดระบบคลังสินค้า" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Claim'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['claim']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.claim', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["claim"] == true ? "เปิดระบบเคลม" : "ปิดระบบเคลม" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Member'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['member']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.member', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["member"] == true ? "เปิดระบบสมัครสมาชิก" : "ปิดระบบสมัครสมาชิก" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Review'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['review']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.review', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["review"] == true ? "เปิดระบบรีวิว" : "ปิดระบบรีวิว" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Account Number'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['accountNumber']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.accountNumber', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["accountNumber"] == true ? "เปิดระบบเลขบัญชี" : "ปิดระบบเลขบัญชี" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Coupon'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['coupon']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.coupon', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["coupon"] == true ? "เปิดระบบคูปอง" : "ปิดระบบคูปอง" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Flash Sale'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['flashSale']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.flashSale', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["flashSale"] == true ? "เปิดระบบลดราคาตามเวลา" : "ปิดระบบลดราคาตามเวลา" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Recommend Product'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['recommendProduct']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.recommendProduct', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["recommendProduct"] == true ? "เปิดระบบสินค้าแนะนำ" : "ปิดระบบสินค้าแนะนำ" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Best Seller'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['bestSeller']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.bestSeller', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["bestSeller"] == true ? "เปิดระบบแสดงสินค้าขายดี" : "ปิดระบบแสดงสินค้าขายดี" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Promotion'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['promotion']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.promotion', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["promotion"] == true ? "เปิดระบบโปรโมชัน" : "ปิดระบบโปรโมชัน" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Voice chat'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['voiceChat']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.voiceChat', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["voiceChat"] == true ? "เปิดระบบแชทด้วยเสียง" : "ปิดระบบแชทด้วยเสียง" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Wholesale Box'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['wholesaleBox']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.wholesaleBox', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["wholesaleBox"] == true ? "เปิดระบบกล่องขายส่ง" : "ปิดระบบกล่องขายส่ง" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Credit'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['credit']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.credit', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["credit"] == true ? "เปิดระบบเครดิต" : "ปิดระบบเครดิต" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.$t('Invite'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['invite']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.invite', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["invite"] == true ? "เปิดระบบเชิญเพื่อน" : "ปิดระบบเชิญเพื่อน" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1),_c('b-tr',[_c('b-td',[_vm._v(_vm._s(_vm.$t('Line Login'))+" : ")]),_c('b-td',[_c('b-row',[_c('b-form-checkbox',{staticClass:"custom-control-primary ml-2",attrs:{"checked":_vm.respData['system'] != null
                    ? _vm.respData['system']['lineLogin']
                    : false,"name":"check-button","switch":""},on:{"change":function($event){return _vm.switchChange('system.lineLogin', $event)}}}),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.respData["system"] != null ? _vm.respData["system"]["lineLogin"] == true ? "เปิดระบบเข้าสู่ระบบไลน์" : "ปิดระบบเข้าสู่ระบบไลน์" : "ยังไม่ได้ตั้งค่า")+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }